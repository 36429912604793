//material-ui
// assets
import { CheckCircleOutlined, CloseCircleOutlined, CloseOutlined, InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Card } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Stack } from '@mui/system';
import { useGetSnackbar } from 'api/snackbar';
// project import
import Loader from 'components/Loader';
// third-party
import { CustomContentProps, SnackbarContent, SnackbarProvider, useSnackbar } from 'notistack';
import { PropsWithChildren, forwardRef } from 'react';
import IconButton from '../@extended/IconButton';

// custom styles
const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
  '&.notistack-MuiContent-default': {
    backgroundColor: theme.palette.primary.main
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: theme.palette.error.main
  },
  '&.notistack-MuiContent-success': {
    backgroundColor: theme.palette.success.main
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: theme.palette.info.main
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: theme.palette.warning.main
  }
}));

// ===========================|| SNACKBAR - NOTISTACK ||=========================== //

interface BlankToastProps extends CustomContentProps {}

const BlankToast = forwardRef<HTMLDivElement, BlankToastProps>((props, ref) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <SnackbarContent ref={ref}>
      <Card sx={{ backgroundColor: 'white', padding: 2 }}>
        <Stack direction={'row'} gap={1}>
          {props.message}
          <IconButton
            onClick={() => {
              closeSnackbar(props.id);
            }}
          >
            <CloseOutlined />
          </IconButton>
        </Stack>
      </Card>
    </SnackbarContent>
  );
});

const Notistack = ({ children }: PropsWithChildren) => {
  const { snackbar } = useGetSnackbar();
  const theme = useTheme();
  const iconSX = { marginRight: 8, fontSize: '1.15rem' };

  if (snackbar === undefined) return <Loader />;

  return (
    <StyledSnackbarProvider
      Components={{
        blank: BlankToast
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: theme.direction === 'ltr' ? 'left' : 'right'
      }}
      maxSnack={snackbar.maxStack}
      autoHideDuration={snackbar.autoHideDuration}
      dense={snackbar.dense}
      iconVariant={
        snackbar.iconVariant === 'useemojis'
          ? {
              success: <CheckCircleOutlined style={iconSX} />,
              error: <CloseCircleOutlined style={iconSX} />,
              warning: <WarningOutlined style={iconSX} />,
              info: <InfoCircleOutlined style={iconSX} />
            }
          : undefined
      }
      hideIconVariant={snackbar.iconVariant === 'hide' ? true : false}
    >
      {children}
    </StyledSnackbarProvider>
  );
};

export default Notistack;
