import { useGetAcceptedLanguages } from '~/api/acceptedLanguages';

export type LanguageCode = 'en' | 'ar' | 'he' | 'el';

export type Language = {
  name: string;
  code: LanguageCode;
  englishName: string;
  isRTL: boolean;
  locale: string;
  acceptLanguageLocale: string;
  flagCode: string;
};

export const AR_SA_LOCALE = 'ar-SA';
export const EN_UK_LOCALE = 'en-UK';

export const useGetSupportedLanguages = () => {
  const {
    query: { data: acceptedLanguages }
  } = useGetAcceptedLanguages();

  const languageOptions: Record<LanguageCode, Language> = {
    en: {
      name: 'English',
      code: 'en',
      englishName: 'English',
      isRTL: false,
      locale: EN_UK_LOCALE,
      acceptLanguageLocale: 'en-US',
      flagCode: 'GB'
    },
    ar: {
      name: 'العربية',
      code: 'ar',
      englishName: 'Arabic',
      isRTL: true,
      locale: AR_SA_LOCALE,
      acceptLanguageLocale: 'ar-SA',
      flagCode: 'SA'
    },
    he: {
      name: 'עברית',
      code: 'he',
      englishName: 'Hebrew',
      isRTL: true,
      locale: 'he-IL',
      acceptLanguageLocale: 'he-IL',
      flagCode: 'IL'
    },
    el: {
      name: 'Ελληνικά',
      code: 'el',
      englishName: 'Greek',
      isRTL: false,
      locale: 'el-GR',
      acceptLanguageLocale: 'el-GR',
      flagCode: 'GR'
    }
  };

  const supportedLanguages = Object.values(languageOptions).filter((language) =>
    acceptedLanguages?.includes(language.acceptLanguageLocale)
  );

  return supportedLanguages;
};
