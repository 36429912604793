import { useQuery, keepPreviousData } from '@tanstack/react-query';
import fitxAxiosServices from '~/utils/fitx-axios';

const getAcceptedLanguages = async () => {
  const { data } = await fitxAxiosServices.get<string[]>('/api/AppGeneral/CountryLanguages');
  return data;
};

export const useGetAcceptedLanguages = () => {
  const query = useQuery({
    queryKey: ['acceptedLanguages'],
    queryFn: getAcceptedLanguages,
    placeholderData: keepPreviousData
  });

  return { query };
};
