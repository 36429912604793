import type { SweetAlertIcon, SweetAlertOptions } from 'sweetalert2';
import { openSnackbar } from './snackbar';

type ShowPopupNotificationOptions = {
  message: string;
  alert: {
    color: 'success' | 'error' | 'info';
  };
  autoHideDuration?: number;
  showSnackbar?: boolean;
};

const getIconBasedOnColor = (color: ShowPopupNotificationOptions['alert']['color']): SweetAlertIcon => {
  if (color === 'error') {
    return 'error';
  }

  if (color === 'success') {
    return 'success';
  }

  return 'info';
};

const mapShowPopupNotificationOptionsToSweetAlertConfig = ({
  autoHideDuration = 3000,
  message,
  alert: { color }
}: ShowPopupNotificationOptions): SweetAlertOptions => {
  return {
    icon: getIconBasedOnColor(color),
    html: message,
    showConfirmButton: false,
    timer: autoHideDuration
  };
};

export const showPopupNotification = (props: ShowPopupNotificationOptions) => {
  if (props.showSnackbar) {
    openSnackbar(props);
  } else {
    const config = mapShowPopupNotificationOptionsToSweetAlertConfig(props);
    import('sweetalert2').then(({ default: mod }) => {
      mod.fire(config);
    });
  }
};
