// project import
import useConfig from 'hooks/useConfig';
import * as PropTypes from 'prop-types';

/* eslint-disable */
import { useEffect, useState } from 'react';
// third-party
import { IntlProvider } from 'react-intl';

// load locales files
const loadLocaleData = (locale) => import(`utils/locales/${locale}`);

// ==============================|| LOCALIZATION ||============================== //

const Locales = ({ children }) => {
  const { i18n } = useConfig();

  const [messages, setMessages] = useState();

  useEffect(() => {
    loadLocaleData(i18n).then((d) => {
      setMessages(d.default);
    });
  }, [i18n]);

  return (
    <>
      {messages && (
        <IntlProvider locale={i18n} defaultLocale="en" messages={messages}>
          {children}
        </IntlProvider>
      )}
    </>
  );
};

Locales.propTypes = {
  children: PropTypes.node
};

export default Locales;
