import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';

export const endpoints = {
  key: 'snackbar'
};

const initialState = {
  action: false,
  open: false,
  message: undefined,
  autoHideDuration: 1500,
  anchorOrigin: { vertical: 'top', horizontal: 'center' },
  variant: 'alert',
  alert: {
    color: 'primary',
    variant: 'filled'
  },
  transition: 'Fade',
  close: false,
  actionButton: false,
  maxStack: 3,
  dense: false,
  iconVariant: 'usedefault'
};

export function useGetSnackbar() {
  const { data } = useSWR(endpoints.key, () => initialState, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });

  const memoizedValue = useMemo(() => ({ snackbar: data }), [data]);

  return memoizedValue;
}

export function openSnackbar(snackbar) {
  // to update local state based on key

  const { action, open, message, anchorOrigin, variant, alert, transition, close, actionButton, autoHideDuration } = snackbar;
  mutate(
    endpoints.key,
    (currentSnackbar) => {
      return {
        ...currentSnackbar,
        action: action || initialState.action,
        open: true,
        message: message || initialState.message,
        autoHideDuration: autoHideDuration || initialState.autoHideDuration,
        anchorOrigin: anchorOrigin || initialState.anchorOrigin,
        variant: variant || initialState.variant,
        alert: {
          color: alert?.color || initialState.alert.color,
          variant: alert?.variant || initialState.alert.variant
        },
        transition: transition || initialState.transition,
        close: close || initialState.close,
        actionButton: actionButton || initialState.actionButton
      };
    },
    false
  );
}

export function closeSnackbar() {
  // to update local state based on key
  mutate(
    endpoints.key,
    (currentSnackbar) => {
      return { ...currentSnackbar, open: false };
    },
    false
  );
}

export function handlerIncrease(maxStack) {
  // to update local state based on key
  mutate(
    endpoints.key,
    (currentSnackbar) => {
      return { ...currentSnackbar, maxStack };
    },
    false
  );
}

export function handlerDense(dense) {
  // to update local state based on key
  mutate(
    endpoints.key,
    (currentSnackbar) => {
      return { ...currentSnackbar, dense };
    },
    false
  );
}

export function handlerIconVariants(iconVariant) {
  // to update local state based on key
  mutate(
    endpoints.key,
    (currentSnackbar) => {
      return { ...currentSnackbar, iconVariant };
    },
    false
  );
}
