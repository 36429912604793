'use client';

import { DefaultOptions, QueryClient, QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import Snackbar from 'components/@extended/Snackbar';
import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Notistack from 'components/third-party/Notistack';
import { ConfigProvider } from 'contexts/ConfigContext';
// next
import { SessionProvider } from 'next-auth/react';
import { ReactNode, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
// project import
import ThemeCustomization from 'themes';
import { showPopupNotification } from '../api/showPopupNotification';
import useConfig from '../hooks/useConfig';
import { useGetSupportedLanguages } from '../hooks/useSupportedLanguages';
import { setCurrentLocaleReqHeader } from '../utils/i18nGlobal';

// ==============================|| APP - THEME, ROUTER, LOCAL ||============================== //

const shouldRetry = (failureCount: number, error: Error) => {
  // If Axios Error and 4xx status code (client error)
  if (error instanceof AxiosError && error.response?.status) {
    const isClientError = Math.floor(error.response.status / 100) === 4;
    if (isClientError) {
      return false;
    }
  }

  return failureCount < 3 ? true : false;
};

const QueriesDefaults: DefaultOptions['queries'] = {
  refetchOnWindowFocus: false, // default: true
  throwOnError: (error) => {
    // eslint-disable-next-line no-console
    console.error(error);
    return false;
  },
  retry: shouldRetry
};

const QueryProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: QueriesDefaults
        }
      }),
    []
  );
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

type ProviderWrapperProps = {
  children: ReactNode;
};

export default function ProviderWrapper({ children }: ProviderWrapperProps) {
  return (
    <QueryProvider>
      <ConfigProvider>
        <ThemeCustomization>
          <RTLLayout>
            <Locales>
              <LanguageQueryClient>
                <ScrollTop>
                  <SessionProvider refetchInterval={0}>
                    <Notistack>
                      <Snackbar />
                      {children}
                    </Notistack>
                  </SessionProvider>
                </ScrollTop>
              </LanguageQueryClient>
            </Locales>
          </RTLLayout>
        </ThemeCustomization>
      </ConfigProvider>
    </QueryProvider>
  );
}

let lastAppliedLocale: string;

function LanguageQueryClient({ children }: { children: ReactNode }) {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const supportedLanguages = useGetSupportedLanguages();
  const { i18n } = useConfig();

  useEffect(() => {
    if (lastAppliedLocale === i18n) {
      return;
    }

    const selectedLanguage = supportedLanguages.find((language) => language.code === i18n);
    if (!selectedLanguage) {
      return;
    }

    lastAppliedLocale = i18n;
    setCurrentLocaleReqHeader(selectedLanguage.acceptLanguageLocale || selectedLanguage.locale);

    queryClient.setDefaultOptions({
      queries: QueriesDefaults,
      mutations: {
        onError(error) {
          // eslint-disable-next-line no-console
          console.error(error);

          if (this.meta?.skipGlobalErrorToast) {
            return;
          }

          let errorToShown = intl.formatMessage({ id: 'something-went-wrong' });

          // If backend send an error message in the body then show it.
          if (error instanceof AxiosError && error?.response?.data?.message) {
            errorToShown = error.response.data.message;
          }

          showPopupNotification({
            message: errorToShown,
            alert: {
              color: 'error'
            }
          });
        }
      }
    });

    queryClient.invalidateQueries();
  }, [i18n, intl, queryClient, supportedLanguages]);

  return children;
}
